@import '../../../../../../themes/main.scss';

.mat-mdc-snack-bar-container {
  &.snack-action {
    // A custom property does not accept variable as value. Only hex codes.
    // To be able to assign a variable to --mdc-snackbar-container-color, background-color should also be present.
    --mdc-snackbar-container-color: $contrast-color;
    background-color: $contrast-color;
    .o8-action-snack-message {
      display: flex;
      align-items: center;

      .action-snack-message {
        margin: 0 10px;
        text-align: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 0.8rem;
        line-height: 1.19;
      }

      .action-snack-buttons-container {
        display: flex;

        .snack-action-button {
          color: $primary-color;

          &:hover {
            background-color: $contrast-light-color;
          }
        }

        .snack-dismiss-button {
          color: $light-color;

          &:hover {
            background-color: $contrast-light-color;
          }
        }
      }
    }
  }
}
