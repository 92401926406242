@import '../../../../../../themes/main.scss';

.mat-mdc-snack-bar-container {
  &.snack-info {
    --mdc-snackbar-container-color: white;

    border: none;
    .snack-info-container {
      display: flex;
      flex-direction: row;
      padding: 15px;
    }

    .snack-info-message {
      color: $primary-color;
      margin: auto;
      text-align: center;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 1.19;
    }
  }
}
