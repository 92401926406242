@import '../../../../../../themes/main.scss';

.mat-mdc-snack-bar-container {
  &.snack-error {
    // A custom property does not accept variable as value. Only hex codes.
    // To be able to assign a variable to --mdc-snackbar-container-color, background-color should also be present.
    --mdc-snackbar-container-color: $error-color;
    background-color: $error-color;
    .snack-error-container {
      display: flex;
      flex-direction: row;
      padding: 15px;
    }

    .snack-error-message {
      margin: auto;
      text-align: center;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 300;
      font-size: 0.8rem;
      line-height: 1.19;
    }
  }
}
